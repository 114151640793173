<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">广东新三板公司协会正式成立，丰海科技成功当选副会长单位
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;">2015-09-18
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">
          
            2015年9月16日下午,广东新三板公司协会第一届会员大会暨第一期新三板公司高管培训班在广州东方宾馆一楼会展A厅隆重举行。广州市丰海科技科技股份有限公司董事长胡亚平应邀出席会议，丰海科技成功当选副会长单位。
           
        </p>
    
  
        <p style="text-indent: .1563rem;">
            丰海科技作为广东新三板公司协会的副会长单位之一，将积极发挥带头作用，进一步规范公司管理机制，提高公司的管理水平，共同促进广东资本市场的繁荣发展。
         
        </p>
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>