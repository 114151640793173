<template>
  <div >
    <div style="display: flex;justify-content: space-between;">
      <el-table :data="getPageData" style="width: 100%">
        <el-table-column prop="name"></el-table-column>
        <el-table-column prop="date"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-link  @click="redirectToSeparateNews(scope.$index + 1 + (currentPage - 1) * pageSize)" >查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex;justify-content: center;margin-top: .2604rem">

      <el-pagination
      @size-change="handleSizeChange"

      @current-change="handleCurrentChange"

      :current-page="currentPage"
      :page-sizes="[10]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNewsCount"></el-pagination>

    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      totalNewsCount: 16,
      tableData: [
        {
          date: '2017-08-06',
          name: '丰海科技与你相约PWTC展会',
          
        },
        {
          date: '2017-06-06',
          name: '成立海外业务部，进军国际市场',
        },
        {
          date: '2017-06-06',
          name: '丰海科技“梅开二度” 入选2017新三板创新层',
        },
        {
          date: '2016-09-06',
          name: '转型升级，打通上下游产业链',
        },
        {
          date: '2016-08-01',
          name: '丰海科技荣获新三板最具价值投资百强奖',
        },
        {
          date: '2016-07-12',
          name: '广州开发区管委会秘书长陈建荣一行莅临公司参观指导',
        },
        {
          date: '2016-06-29',
          name: '新三板分层落地，丰海科技首批进入创新层',
        },
        {
          date: '2016-03-30',
          name: '齐聚金陵，参加第十八届高速公路技术产品展',
        },
        {
          date: '2016-01-25',
          name: '丰海科技被认定为广州开发区瞪羚企业',
        },
        {
          date: '2015-09-18',
          name: '广东新三板公司协会正式成立，丰海科技成功当选副会长单位',
        },
        {
          date: '2015-04-06',
          name: '热烈祝贺丰海科技被认定为“高新技术企业”',
        },
        {
          date: '2015-03-31',
          name: '三亚欢乐游',
        },
        {
          date: '2015-03-23',
          name: '丰海科技应邀参加第十七届中国高速公路信息化研讨会暨技术产品展示会',
        },
        {
          date: '2015-01-17',
          name: '热烈祝贺丰海科技（830862）股票开始做市交易',
        },
        {
          date: '2014-07-18',
          name: '热烈祝贺丰海科技成功登陆“新三板”',
        },
        {
          date: '2014-05-20',
          name: '难忘之旅-记阳江闸坡自由岛拓展旅游活动',
        }
      ], // 新闻数据源
    };
  },
  computed: {
    getPageData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.tableData.slice(startIndex, endIndex);
    }
  },
  methods: {
    redirectToSeparateNews(index) {
      this.$router.push(`/separateNews${index}`);
    },
  
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchNewsData();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.fetchNewsData();
    },
    fetchNewsData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.getPageData = this.tableData.slice(startIndex, endIndex);
    }
  },
  mounted() {
    this.fetchNewsData();
  }
};
</script>